export default {
  media: {
    height: "25vw",
    maxHeight: "140px",
  },
  container: {
    fontFamily: "Lato",
    paddingBottom: "50px",
  },
  text: {
    textAlign: "-webkit-center",
  },
};
