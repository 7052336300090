export default {
  media: {
    height: "25vw",
    maxHeight: "140px",
  },
  container: {
    fontFamily: "Lato",
    paddingBottom: "50px",
  },
  titleText: {
    textAlign: "center",
  },
  gridList: {
    padding: "20px",
  },
  gridListTile: {
    textAlign: "center",
  },
  img: {
    height: "100px !important",
    width: "auto !important",
    verticalAlign: "middle",
    top: "0%",
    transform: "initial",
  },
  button: {
    display: "block",
    margin: "auto",
  },
  formControlLabel: {
    maxWidth: "300px",
  },
  circularProgressBox: {
    textAlign: "center",
    margin: "40px auto",
  },
};
