import axios from "axios";

const getOrderDetailsByVtexOrderId = async (vtexOrderId) => {
  return await axios.get(
    `https://api-tryathome-dot-apt-bonbon-179602.ue.r.appspot.com/private/order/${vtexOrderId}/details`,
    { headers: { token: "de72014d-a924-4c83-9618-47741d69de34" } }
  );
};

const requestReturnByOrderId = async (orderDetails) => {
  return await axios.post(
    `https://api-tryathome-dot-apt-bonbon-179602.ue.r.appspot.com/order/${orderDetails.id_order}/request_return`,
    orderDetails
  );
};

export { getOrderDetailsByVtexOrderId, requestReturnByOrderId };
