import axios from "axios";

const sendReverseFeedback = async (body) => {
  return await axios.post(
    "https://api-tryathome-dot-apt-bonbon-179602.ue.r.appspot.com/reverse/csat",
    body
  );
};

export default sendReverseFeedback;
