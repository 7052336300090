import React from "react";
import { Container, CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import uniqueBoxLogo from "../../../assets/logo_unique_box.png";
import styles from "./styles";

const useStyles = makeStyles(styles);

const RequestReturnMessage = (props) => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="sm" className={classes.container}>
        <CardMedia image={uniqueBoxLogo} className={classes.media}></CardMedia>
        {props.match.params.status === "success" && (
          <Typography variant="h6" className={classes.text}>
            {`A devolução/strike do pedido ${props.match.params.id_vtex_order} foi solicitada com sucesso.`}
          </Typography>
        )}
        {props.match.params.status === "error" && (
          <Typography variant="h6" className={classes.text}>
            {`Houve um erro na solicitação da devolução/strike para o pedido ${props.match.params.id_vtex_order}.`}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default RequestReturnMessage;
