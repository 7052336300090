export default {
  media: {
    height: "25vw",
    maxHeight: "140px",
  },
  container: {
    fontFamily: "Lato",
    paddingBottom: "50px",
  },
  buttonBox: {
    padding: "15px",
    textAlign: "center",
  },
  button: {
    margin: "20px 20px",
    width: "110px",
  },
  box: {
    textAlign: "-webkit-center",
  },
  textField: {
    margin: "25px",
  },
  modal: { display: "flex" },
  modalOuterBox: {
    margin: "auto",
    height: "45%",
    maxHeight: "280px",
    "@media (min-width: 900px)": { height: "230px" },
    width: "40%",
    minWidth: "300px",
    backgroundColor: "white",
  },
  modalInnerBox: {
    padding: "20px",
    textAlign: "center",
  },
  modalButtonBox: {
    padding: "15px",
    textAlign: "center",
  },
  modalButton: {
    margin: "20px",
    width: "75px",
  },
  circularProgressBox: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
