import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';

import Routes from './routes/index';
import Theme from './themes/index';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
