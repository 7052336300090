import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Lato",
  },
  overrides: {
    MuiList: {
      root: {
        listStyleType: "decimal",
        marginLeft: '0.5rem'
      },
    },
    MuiListItem: {
      root: {
        display: "list-item",
        marginLeft: '0.5rem'
      },
    },
    MuiTypography: {
      root: {
        paddingBottom: "8px",
        paddingTop: "8px",
      },
    }
  },
});

export default theme;
