import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import style from "./styles";

import sendReverseFeedback from "../../services/api/SendReverseFeedbackService";

const useStyles = makeStyles((theme) => style(theme));

const ReverseCSAT = (props) => {
  const [autocompleteValue, setAutocompleteValue] = useState(
    String(props.match.params.initial_rating)
  );
  const [textfieldValue, setTextfieldValue] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const saveInitialRating = async () => {
      const body = {
        id_yami_protocol: props.match.params.id_yami_protocol,
        rating: Number(autocompleteValue),
      };
      try {
        await sendReverseFeedback(body);
      } catch (error) {}
    };

    document.title = "Feeback";
    saveInitialRating();
  }, [
    props.match.params.id_yami_protocol,
    props.match.params.brand,
    autocompleteValue,
  ]);

  const sendFeedback = async () => {
    try {
      const body = {
        id_yami_protocol: props.match.params.id_yami_protocol,
        rating: Number(autocompleteValue),
        feedback: textfieldValue,
      };
      const response = await sendReverseFeedback(body);
      toast.success(response.data.message);
      return response;
    } catch (error) {
      toast.error(error.response.data.message);
      return error;
    }
  };

  const selectOptions = [...Array(11).keys()].map((i) => String(i));

  const getBrandLogo = (brand) => {
    try {
      return require(`../../assets/brandLogos/logo_${brand}.png`);
    } catch (error) {
      return require("../../assets/brandLogos/logo_gruposoma.png");
    }
  };

  return (
    <Box className={classes.bodyBox}>
      <Container maxWidth="sm" className={classes.container}>
        <img
          src={getBrandLogo(props.match.params.brand)}
          className={classes.media}
          alt="logo"
        ></img>
        <Typography variant="body2" className={classes.bodyText}>
          Você nos classificou com
        </Typography>
        <Box className={classes.selectBox}>
          <Autocomplete
            value={autocompleteValue}
            onChange={(event, newValue) => {
              setAutocompleteValue(newValue);
            }}
            classes={{ clearIndicator: classes.clearIndicator }}
            options={selectOptions}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Box>
        <Typography variant="body2" className={classes.bodyText}>
          Gostaria de explicar sua resposta?
        </Typography>
        <Box className={classes.textFieldBox}>
          <TextField
            value={textfieldValue}
            onChange={(e) => {
              setTextfieldValue(e.target.value);
            }}
            multiline
            rows={8}
            label="Escreva seu comentário aqui..."
            variant="outlined"
            className={classes.textField}
          />
        </Box>
        <Button className={classes.button} onClick={sendFeedback}>
          Enviar feedback
        </Button>
        <ToastContainer />
      </Container>
    </Box>
  );
};

export default ReverseCSAT;
