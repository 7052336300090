import React, { useEffect } from "react";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Dashboards = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Dashboards";
  }, []);

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h4">
          Dashboards - Tribo Novas Experiências
        </Typography>
        <Typography variant="h6">Squad Experimentação e Reversa:</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" href="/dashboards/tryathome/reversa">
              REVERSA
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/tryathome/reversa-indicadores"
            >
              REVERSA INDICADORES
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" href="/dashboards/tryathome/atendimento">
              ATENDIMENTO
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/tryathome/unique-box-vendedor"
            >
              UNIQUE BOX VENDEDOR
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/tryathome/unique-box-indicadores"
            >
              UNIQUE BOX INDICADORES
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h6">Squad Somalive:</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/somalive/resultados-lives"
            >
              RESULTADOS LIVES
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/somalive/somalive-historico-2020-2021"
            >
              HISTÓRICO 2020/2021
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              href="/dashboards/somalive/farm-na-nuvem"
            >
              FARM NA NUVEM
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboards;
