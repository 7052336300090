import React, { useState } from "react";
import {
  Container,
  CardMedia,
  Box,
  Typography,
  TextField,
  Button,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.min.css";

import uniqueBoxLogo from "../../../assets/logo_unique_box.png";
import requestStrikeByVtexOrderId from "../../../services/api/RequestStrikeService";
import styles from "./styles";

const useStyles = makeStyles(styles);

const RequestReturn = (props) => {
  const classes = useStyles();

  const [idVtexOrder, setIdVtexOrder] = useState(
    String(props.match.params.id_vtex_order)
  );
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const goToOrderItemsPage = () => {
    window.open(`/unique-box/${idVtexOrder}/products`, "_self");
  };

  const requestStrike = async () => {
    try {
      setStatus("idle")
      await requestStrikeByVtexOrderId(idVtexOrder);
      window.open(
        `/unique-box/${idVtexOrder}/success`,
        "_self"
      );
    } catch (error) {
      window.open(
        `/unique-box/${idVtexOrder}/error`,
        "_self"
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm" className={classes.container}>
        <CardMedia image={uniqueBoxLogo} className={classes.media}></CardMedia>
        <Box className={classes.box}>
          <Typography variant="h6">
            O strike é quando o seu cliente decidiu{" "}
            <strong>ficar com todas as peças</strong> da box. Para qual pedido
            você deseja solicitar a devolução ou strike?
          </Typography>
          <TextField
            required
            label="Pedido"
            value={idVtexOrder}
            onChange={(e) => {
              setIdVtexOrder(e.target.value);
            }}
            variant="outlined"
            className={classes.textField}
          />
        </Box>
        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            color="default"
            onClick={goToOrderItemsPage}
            className={classes.button}
          >
            Devolução
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={handleModalOpen}
            className={classes.button}
          >
            Strike
          </Button>
        </Box>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.modalOuterBox}>
            {status === "idle" && (
              <Box
                className={classes.circularProgressBox}
                sx={{ display: "flex" }}
              >
                <CircularProgress />
              </Box>
            )}
            {status !== "idle" && (
              <Box className={classes.modalInnerBox}>
                <Typography variant="h6">
                  Essa ação <strong>não poderá ser desfeita</strong>. Tem
                  certeza de que deseja solicitar o strike do pedido{" "}
                  {idVtexOrder}?
                </Typography>
                <Box className={classes.modalButtonBox}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={requestStrike}
                    className={classes.modalButton}
                  >
                    Sim
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleModalClose}
                    className={classes.modalButton}
                  >
                    Não
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default RequestReturn;
