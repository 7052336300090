export default (theme) => ({
  bodyBox: {
    backgroundColor: "gray",
    height: "100%",
  },
  media: {
    display: "block",
    margin: "auto",
    maxWidth: "50%",
    maxHeight: "30%",
  },
  bodyText: {
    fontSize: "1.2rem",
    lineHeight: "1.5",
    textAlign: "center",
  },
  container: {
    fontFamily: "Lato",
    paddingBottom: "50px",
    backgroundColor: "white",
    height: "100%",
  },
  button: {
    display: "block",
    margin: "auto",
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(0, 0, 0, .87)",
    border: "1px solid rgba(0, 0, 0, .25)",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, .87)",
    },
  },
  typography: {
    display: "flex",
  },
  selectBox: {
    textAlign: "center",
    margin: "0 auto",
    width: "10%",
    minWidth: "82px",
  },
  textField: {
    width: "100%",
  },
  textFieldBox: {
    textAlign: "center",
    marginBottom: "20px",
  },
  clearIndicator: {
    display: "none",
  },
});
