import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Checkbox,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  Button,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import uniqueBoxLogo from "../../assets/logo_unique_box.png";
import styles from "./styles";

const useStyles = makeStyles(styles);

const TermsAndConditions = (props) => {
  const [buttonState, setButtonState] = useState(true);

  const classes = useStyles();

  const updateAcceptedTermsDate = async () => {
    try {
      const response = await axios.patch(
        `https://api-tryathome-dot-apt-bonbon-179602.ue.r.appspot.com/client/${props.match.params.client_email}/accept_terms`
      );
      toast.success(response.data.message);
      return response;
    } catch (error) {
      toast.error(error.response.data.message);
      return error;
    }
  };

  return (
    <>
      <Container maxWidth="sm" className={classes.container}>
        <CardMedia image={uniqueBoxLogo} className={classes.media}></CardMedia>
        <Typography variant="h5">O QUE É A U.NIQUE BOX?</Typography>
        <Typography variant="body2" className={classes.body}>
          U.NIQUE BOX é um programa que permite aos nossos clientes especiais
          receberem caixas com roupas escolhidas por si e por seu vendedor
          preferencial para experimentarem na comodidade de suas casas e
          devolverem o que não quiserem comprar.
        </Typography>
        <Typography variant="h5">COMO FUNCIONA?</Typography>
        <List component="ol">
          <ListItem>
            Inicialmente, importante falar que, ao aderir a este Termo, você
            declara estar de acordo com todas as condições do programa,
            incluindo, mas não se limitando, às formas de pagamento, as
            pré-autorizações de pagamento das peças enviadas e as condições de
            pagamento das peças escolhidas. De toda forma, quaisquer dúvidas
            sempre poderão ser tiradas direta e preferencialmente com seu
            vendedor ou no nosso canal de atendimento, através do e-mail
            atendimento@animale.com.br.
          </ListItem>
          <ListItem>
            Você precisará indicar um endereço preciso e válido para recebimento
            da caixa, a qual será entregue em um prazo de até 05 (cinco) dias
            úteis após a confirmação do seu pedido. Desde já, por favor fique
            atento que, na hipótese de entrega em endereço errado por conta do
            fornecimento de informações divergentes, o cliente poderá ter que
            arcar com os custos adicionais de transportes e dos produtos
            constantes da caixa em caso de perdimento.
          </ListItem>
          <ListItem>
            A dinâmica ocorre através de um vendedor habilitado para participar
            deste programa que entrará em contato com você e selecionará as
            peças com base no seu estilo, preferências, tamanho, perfil, entre
            outras necessidades e escolhas, sempre quando for do seu interesse.
          </ListItem>
          <ListItem>
            É importante ressaltar que nem todas as peças encontradas no site ou
            lojas físicas podem estar disponíveis para este programa.
          </ListItem>
          <ListItem>
            Inclusive, algumas promoções e remarcações de valor também podem não
            se aplicar a este programa, portanto caso tenha qualquer dúvida a
            respeito, consulte previamente seu vendedor.
          </ListItem>
          <ListItem>
            Após a seleção dos produtos com o seu vendedor, você receberá um
            link do nosso site acusando o checkout dos itens escolhidos. Você
            não pagará pelas peças neste momento. Essas peças ficarão
            emprestadas pela empresa a você pelo prazo de 02 (dois) dias
            corridos, podendo ser estendido por mais 03 (três) dias corridos,
            mediante acordo prévio e expresso com seu vendedor.
          </ListItem>
          <ListItem>
            Após a confirmação deste pedido, você receberá em casa uma caixa
            lacrada com as peças, uma relação dos itens escolhidos e seus
            valores (nota de remessa) e um kit de devolução, composto por um
            card, lacre e um saco adesivado. Confira se a caixa está lacrada e
            com lacre não violado. Caso a caixa esteja com lacre violado, você
            deverá recusar seu recebimento e entrar em contato com seu vendedor
            para informa-lo sobre o ocorrido, imediatamente, por gentileza.
          </ListItem>
          <ListItem>
            Você receberá em seu e-mail – aquele indicado por você no momento do
            cadastro – um comprovante dos itens selecionados pelo seu vendedor
            para que todos tenhamos controle do que está sendo enviado na caixa.
          </ListItem>
          <ListItem>
            Após a abertura da caixa, caso eventualmente verifique que há alguma
            peça danificada, comunique imediatamente ao seu vendedor. Tais
            comunicações - itens 7 e 9 - deverão ocorrer em 24 (vinte e quatro)
            horas do recebimento para que tenham validade e produzam seus
            respectivos efeitos.
          </ListItem>
          <ListItem>
            Após 02 (dois) dias corridos do recebimento, seu vendedor fará
            contato para agendar o recolhimento das peças que serão devolvidas.
            A coleta será realizada em até 02 (dois) dias úteis contados do
            contato do vendedor. Você deverá utilizar a mesma caixa do envio das
            mercadorias para fazer o retorno. Coloque na caixa apenas as peças
            que não desejar adquirir e lacre a caixa com o lacre extra enviado
            no kit de devolução, a fim de garantir a integridade do seu
            conteúdo. Você também deverá anexar à caixa, por meio do saco
            adesivo, a nota de remessa (mencionada no item 7).
          </ListItem>
          <ListItem>
            O portador fará até 02 (duas) tentativas de coleta. A não devolução
            dos itens, sem justificativa prévia adequada, poderá ser reconhecida
            como aquisição das peças não devolvidas, e será emitida uma fatura
            no valor destas peças a ser paga.
          </ListItem>
          <ListItem>
            Caso deseje ficar com todas as peças, informe ao seu vendedor e
            assim não será necessária a coleta da caixa.
          </ListItem>
          <ListItem>
            Todas as caixas antes de serem entregues para a coleta deverão ser
            lacradas pelo cliente, com o lacre extra enviado dentro da própria
            caixa para garantir a integridade do seu conteúdo, conforme indicado
            no item 10. Não receberemos caixas não lacradas. Todas as peças
            devolvidas não poderão ter sido usadas e deverão estar etiquetadas.
            Itens retornados sem etiqueta, sujos, avariados ou com indícios de
            uso serão cobrados, mediante prévio contato, informando o estado da
            peça recebida e a necessidade de cobrança.
          </ListItem>
          <ListItem>
            Caso você tenha optado por ficar com algum dos produtos enviados,
            receberá por seu vendedor um link para o pagamento. Somente serão
            aceitos pagamentos em cartão de crédito, cujas condições são as
            mesmas praticadas em nosso site. O valor cobrado pelas peças será o
            mesmo listado na nota de remessa, que se refere ao seu valor no ato
            do pedido. Oscilações de preço entre a envio da caixa e o pagamento
            não afetarão o valor cobrado.
          </ListItem>
          <ListItem>
            Após a comunicação ao seu vendedor sobre as peças selecionadas para
            aquisição, nosso sistema dará baixa na nota de remessa e realizará o
            faturamento dos itens escolhidos. Caso desista da compra das peças
            após informar ao vendedor que ficará com todas as mercadorias
            enviadas ou após a entrega da caixa com os produtos que não
            selecionou, o estorno dos valores correspondentes às peças
            escolhidas será realizado mediante devolução das mesmas, devendo
            estar em perfeitas condições, conforme descrito no item 13.
          </ListItem>
          <ListItem>
            Em caso de impossibilidade de entrega da caixa por erro no
            preenchimento do endereço ou recusa no recebimento, à exceção da
            hipótese de caixa com lacre violado, tentaremos uma segunda vez.
          </ListItem>
          <ListItem>
            Caso haja algum problema ou divergência com o valor enviado para
            pagamento, você deverá contatar o vendedor, por gentileza.
          </ListItem>
          <ListItem>
            Após a confirmação do pagamento, você receberá um e-mail com a Nota
            Fiscal dos produtos comprados, podendo entrar em contato com seu
            vendedor ou através do canal indicado em nosso site caso não a
            receba, em uma remotíssima hipótese, para solicitar seu reenvio.
          </ListItem>
          <ListItem>
            A Animale reserva-se ao direito de alterar o presente termo a
            qualquer tempo, sempre comunicando ao cliente quanto às mudanças
          </ListItem>
        </List>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              name="termsAndConditionsCheckbox"
              color="primary"
              onChange={(e) => setButtonState(!e.target.checked)}
            />
          }
          label="Eu li e aceito os termos e condições"
        />
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          disabled={buttonState}
          onClick={updateAcceptedTermsDate}
        >
          Enviar
        </Button>
        <ToastContainer />
      </Container>
    </>
  );
};

export default TermsAndConditions;
