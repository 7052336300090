import React, { useState, useEffect } from "react";
import {
  Container,
  CardMedia,
  Typography,
  GridList,
  GridListTile,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import uniqueBoxLogo from "../../../assets/logo_unique_box.png";
import {
  getOrderDetailsByVtexOrderId,
  requestReturnByOrderId,
} from "../../../services/api/RequestReturnService";
import styles from "./styles";

const useStyles = makeStyles(styles);

const RequestReturnProducts = (props) => {
  const classes = useStyles();

  const [orderDetails, setOrderDetails] = useState({ items: [] });
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const response = await getOrderDetailsByVtexOrderId(
          props.match.params.id_vtex_order
        );
        setOrderDetails(response.data);
        setStatus("resolved");
        return response;
      } catch (error) {
        setStatus("rejected");
      }
    };
    getOrderDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckboxChange = (e, vtexSkuId) => {
    const arrayIndex = orderDetails.items.findIndex(
      (obj) => obj.id_sku_vtex === vtexSkuId
    );
    if (e.target.checked) {
      orderDetails.items[arrayIndex].requested_sold = 0;
    } else {
      orderDetails.items[arrayIndex].requested_sold = 1;
    }
  };

  const requestReturn = async () => {
    try {
      setStatus("idle");
      await requestReturnByOrderId(orderDetails);
      window.open(
        `/unique-box/${props.match.params.id_vtex_order}/success`,
        "_self"
      );
    } catch (error) {
      window.open(
        `/unique-box/${props.match.params.id_vtex_order}/error`,
        "_self"
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm" className={classes.container}>
        <CardMedia image={uniqueBoxLogo} className={classes.media}></CardMedia>
        {status === "idle" && (
          <Box className={classes.circularProgressBox} sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
        {status === "resolved" && (
          <>
            <Typography variant="h6" className={classes.titleText}>
              Selecione os produtos que a cliente deseja devolver.
            </Typography>
            <GridList cellHeight={"auto"} cols={1} className={classes.gridList}>
              {orderDetails.items.map((item) => (
                <GridListTile
                  key={item.id_sku_vtex}
                  className={classes.gridListTile}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          item.requested_sold === 0 ? true : false
                        }
                        onChange={(e) =>
                          handleCheckboxChange(e, item.id_sku_vtex)
                        }
                        name={`${item.id_sku_vtex}`}
                        color="primary"
                      />
                    }
                    className={classes.formControlLabel}
                    label={`${item.description}`}
                  />
                  <img
                    src={`https://lojaanimale.vteximg.com.br/arquivos/ids/${item.image_url}`}
                    alt={item.description}
                    className={classes.img}
                    loading="lazy"
                  />
                </GridListTile>
              ))}
            </GridList>
            <Button
              variant="contained"
              color="default"
              onClick={requestReturn}
              className={classes.button}
            >
              Solicitar Devolução
            </Button>
          </>
        )}
        {status === "rejected" && (
          <Typography variant="h6" className={classes.titleText}>
            Houve algum erro na requisição.
          </Typography>
        )}
      </Container>
    </>
  );
};

export default RequestReturnProducts;
