export default {
  media: {
    height: "25vw",
    maxHeight: "140px",
  },
  body: {
    fontSize: "1rem",
    lineHeight: "1.25",
  },
  container: {
    fontFamily: "Lato",
    paddingBottom: "50px",
  },
  button: {
    display: "block",
    margin: "auto",
  },
  formControlLabel: {
    fontFamily: "Lato",
    textAlign: "center",
    display: "block",
  },
};
