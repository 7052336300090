import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TermsAndConditions from "../components/TermsAndConditions/index";
import ReverseCSAT from "../components/ReverseCSAT/index";
import Dashboards from "../components/Dashboards/index";
import Dashboard from "../components/Dashboard/index";
import RequestReturn from "../components/RequestReturn/RequestReturn/index";
import RequestReturnProducts from "../components/RequestReturn/RequestReturnProducts/index";
import RequestReturnMessage from "../components/RequestReturn/RequestReturnMessage/index";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/unique-box" component={RequestReturn} />
        <Route
          exact
          path="/unique-box/:id_vtex_order"
          component={RequestReturn}
        />
        <Route
          exact
          path="/unique-box/:id_vtex_order/products"
          component={RequestReturnProducts}
        />
        <Route
          exact
          path="/unique-box/:id_vtex_order/:status"
          component={RequestReturnMessage}
        />
        <Route
          exact
          path="/unique-box/:client_email/terms"
          component={TermsAndConditions}
        />
        <Route
          path="/reverse/csat/:brand/:id_yami_protocol/:initial_rating"
          component={ReverseCSAT}
        />
        <Route exact path="/dashboards" component={Dashboards} />
        <Route
          path="/dashboards/tryathome/reversa"
          component={() => (
            <Dashboard
              name="Reversa"
              link="https://app.powerbi.com/view?r=eyJrIjoiNjE1YWRlMWEtZTAyOS00ZTZkLWIyNjctOGVkMjAwODcxMzQ4IiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/tryathome/reversa-indicadores"
          component={() => (
            <Dashboard
              name="Reversa Indicadores"
              link="https://app.powerbi.com/view?r=eyJrIjoiNzg0ZTkzYmUtNWQ5MS00MmQzLWIwMjUtNDFlZTIzYzc1MmRjIiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/tryathome/atendimento"
          component={() => (
            <Dashboard
              name="Atendimento"
              link="https://app.powerbi.com/view?r=eyJrIjoiYjMyNmFkMDQtYWIyMS00NzE1LThhNjMtZTFiZmFmYjkxMTZkIiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/tryathome/unique-box-vendedor"
          component={() => (
            <Dashboard
              name="Unique Box Vendedor"
              link="https://app.powerbi.com/view?r=eyJrIjoiN2FjNzc2ZTYtMDY5My00OTZiLWIyMTYtYzcxNTRkYjFmMWU5IiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/tryathome/unique-box-indicadores"
          component={() => (
            <Dashboard
              name="Unique Box Indicadores"
              link="https://app.powerbi.com/view?r=eyJrIjoiOWE1MGIzODctMTM1Yy00OWJlLThjZmYtMjBiNDY0ZmI3NmIzIiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />

        <Route
          path="/dashboards/somalive/resultados-lives"
          component={() => (
            <Dashboard
              name="Resultados Lives"
              link="https://app.powerbi.com/view?r=eyJrIjoiMTBmOWZlMjYtMjgzYS00OTU4LWE1MDAtNjViNzBkMzU3ZGYyIiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/somalive/somalive-historico-2020-2021"
          component={() => (
            <Dashboard
              name="Somalive Histórico 2020/2021"
              link="https://app.powerbi.com/view?r=eyJrIjoiY2Q5OTQzZTUtMjljOC00YjM1LTkyOTMtZWYyMTJhYmNlZjE0IiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
        <Route
          path="/dashboards/somalive/farm-na-nuvem"
          component={() => (
            <Dashboard
              name="FARM na Nuvem"
              link="https://app.powerbi.com/view?r=eyJrIjoiMjg2OTk5ZTYtNDFlNy00MWE5LTgxZmEtNjM2M2MzZjdhYzlkIiwidCI6ImU0OGJkNWUxLTUyY2QtNDFmNS04N2I5LTFkMjVjMGQxMDhiNiJ9"
            />
          )}
        />
      </Switch>
    </Router>
  );
}
