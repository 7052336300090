import React, { useEffect } from "react";

function Dashboard(props) {
  useEffect(() => {
    document.title = props.name;
  }, [props.name]);

  return (
    <>
      <div style={{ height: "100vh" }}>
        <iframe
          title={props.name}
          src={props.link}
          frameBorder="0"
          allowFullScreen={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
}

export default Dashboard;
